// Libs
import React from 'react';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Select, Tooltip, DatePicker, TreeSelect } from 'antd';
import { calculatePercentage } from 'components/progress';
import { BadgeSize } from 'components/badge';

// Services
import { getUserSetting } from 'services/settings';

// Utils
import { nestedSet, findFirst } from 'utils/utils';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

export interface ListData {
  [key: string]: ListDataValues;
  children: any;
};

export interface ListDataValues {
  type: string;
  value: any;
  title?: string;
  total?: number;
  value_total?: number;
  prefix?: string | null;
  suffix?: string | null;
  format?: string | null;
  text?: string | number;
  color?: string;
  code?: string;
  unit?: string;
  old?: string;
  new?: string;
  size?: BadgeSize;
  path?: string;
  filename?: string;
};

interface Props {
  columns: any;
  items: any;
  dateRangeFilterFields?: string[];
  defaultFilters?: any[];
  onFilter: (items: any) => void;
};

interface State {
  filters: any[];
  tooltip: any;
};

class Filters extends React.Component<Props, State> {

  state: State = {
    filters: this.props.defaultFilters && !_.isEmpty(this.props.defaultFilters) ? this.props.defaultFilters : [],
    tooltip: null,
  };

  componentDidMount() {
    if (this.props?.defaultFilters) {
      this.props.onFilter(this.filter(this.props.items, this.props.columns, this.props.defaultFilters));
    }
  };

  componentDidUpdate = (__: Props, prevState: State) => {
    if (!_.isEqual(prevState.filters, this.state.filters)) {
      this.props.onFilter(this.filter(this.props.items, this.props.columns, this.state.filters));
    }
  };

  setPrivateState = (state: State) => {
    this.setState(state);
  };

  filter = (items: any[], columns: any, filters: any) => {
    const { dateRangeFilterFields = [] } = this.props;
    return items.filter((item: any) => {
      return Object.keys(filters).every(key => {

        const column = columns.find((column: any) => column.key === key);

        if (column && column.type === 'nested') {
          return filters[key].some((nestedId: number | string) => {

            const currentOption = findFirst({ children: column.options }, 'children', { id: nestedId });

            if (item[key]?.type === 'route' && item[key]?.value?.title) {
              return findFirst(currentOption, 'children', { title: item[key].value.title });
            }

            return findFirst(currentOption, 'children', { id: item[key]?.id });
          });
        }

        // For year and other fields that are plain strings, ensure consistent comparison.
        if (typeof item[key] === 'string' && typeof filters[key] === 'string') {
          return filters[key] === item[key];
        }

        // If `item[key]` is an object with a `title` property, compare based on the title.
        if (typeof item[key] === 'object' && _.has(item[key], 'type')) {
          if (item[key].type === 'nested' && item[key].title) {
            return filters[key].includes(item[key].title);
          }
        }

        // For the date range and other filter checks...
        if (dateRangeFilterFields.includes(key) && _.has(item, `${key}`) && !_.isEmpty(filters[key])) {
          const { startDate, endDate } = filters[key];
          const targetDate = moment(item[key], getUserSetting('date_format'));

          if (!startDate) {
            return targetDate.isBefore(endDate);
          }

          return targetDate.isBetween(startDate, endDate, 'days', '[]');
        }

        if (typeof item[key] === 'object' && _.has(item[key], 'type')) {
          if (!item[key].value && filters[key].includes('<blank>')) {
            return true;
          }

          switch (item[key].type) {
            case 'string':
              return filters[key].includes(item[key].value);
            case 'route':
              return filters[key].includes(item[key].value.title);
            case 'workflow_stage':
              return filters[key].includes(item[key].value.title);
            case 'sublist':
              return filters[key].some((filter: string) => {
                return findFirst({ children: item[key].value }, 'children', { title: filter });
              });
            case 'badge':
              return filters[key].includes(item[key].text);
            case 'progress':
              if (filters[key].includes('Complete')) {
                return calculatePercentage(item[key].total, item[key].value) === 100;
              } else if (filters[key].includes('Incomplete')) {
                return calculatePercentage(item[key].total, item[key].value) < 100;
              }
          }

          return false;
        } else {
          if (['Yes', 'No'].includes(filters[key])) {
            const truthy = filters[key] === 'Yes';
            return truthy === item[key];
          } else if (filters[key][0] === '<blank>' && _.isEmpty(item[key])) {
            return true;
          } else {
            return filters[key].includes(item[key]);
          }
        }
      });
    });
  };

  renderFilterSelect = (column: any, rows: any) => {
    const { filters, tooltip } = this.state;

    let multiple = true;
    let uniqeItems;

    // Handle specific case for "year" filter
    if (column.key === 'year') {
      uniqeItems = rows
        .filter((row: any) => !!row && _.has(row, `values.${column.key}`))
        .map((row: any) => {
          const yearData = row.values[column.key];
          if (yearData && yearData.title) {
            return yearData.title.toString();
          }
          return '<blank>';
        })
        .filter((value: any, index: any, self: any) => self.indexOf(value) === index);

      // Sort years in descending order
      uniqeItems.sort((a: string, b: string) => {
        const [aStart] = a.split(' - ').map((year) => parseInt(year, 10));
        const [bStart] = b.split(' - ').map((year) => parseInt(year, 10));
        return bStart - aStart; // Sort in descending order
      });
    } else {
      // Default filter extraction
      uniqeItems = rows
        .filter((row: any) => !!row && _.has(row, `${column.key}`))
        .map((row: any) => {
          const _row = row[column.key];
          if (_.isBoolean(_row)) {
            multiple = false;
            return !!_row ? 'Yes' : 'No'; // Booleans
          } else if (!!_row && typeof _row === 'object') {
            switch (_row.type) {
              case 'string':
                return _row.value || '<blank>';
              case 'route':
                return _row.value.title || '<blank>';
              case 'workflow_stage':
                return _row.value.title || '<blank>';
              case 'badge':
                return _row.text || '<blank>';
              case 'progress':
                return calculatePercentage(_row.total, _row.value) !== 100 ? 'Incomplete' : 'Complete';
            }
            return '<blank>';
          } else {
            return _row || '<blank>';
          }
        })
        .filter((value: any, index: any, self: any) => self.indexOf(value) === index);

      // Sort alphabetically for non-year columns
      uniqeItems.sort((a: any, b: any) => a.toString().localeCompare(b));
    }

    // No items found
    if (_.isEmpty(uniqeItems)) return;
    return (
      <Tooltip key={ column.key } visible={ tooltip === column.key } title={ `Filter by ${column?.label || column?.title || '-' }` }>
        <Select
          mode={ multiple ? 'multiple' : undefined }
          allowClear
          style={{ width: 200, margin: 5 }}
          placeholder={ column.label || column.title }
          dropdownMatchSelectWidth={ false }
          onMouseEnter={ () => {
            filters[column.key] &&
              this.setState({
                tooltip: column.key,
              });
          } }
          onMouseLeave={ () => {
            tooltip &&
              this.setState({
                tooltip: null,
              });
          } }
          onChange={ (value) => {
            let newFilters = _.cloneDeep(filters);
            if (!value || _.isEmpty(value)) {
              delete newFilters[column.key];
            } else {
              newFilters = Object.assign({}, filters, { [column.key]: value });
            }
            this.setState({
              filters: newFilters,
            });
          }}
          value={ filters[column.key] ? filters[column.key] : [] }
        >
          { uniqeItems.map((uniqeItem: any, index: any) => (
            <Option key={ index } value={ uniqeItem }>
              { uniqeItem }
            </Option>
          ))}
        </Select>
      </Tooltip>
    );
  };

  renderNestedSelect = (column: any, rows: any) => {
    const { filters } = this.state;

    return (
      <TreeSelect
        key={ column?.key || column?.id }
        style={{ width: 200, margin: 5 }}
        dropdownMatchSelectWidth={ false }
        placeholder={ column?.label || column.title }
        showCheckedStrategy={ SHOW_PARENT }
        maxTagCount={ 5 }
        treeDefaultExpandedKeys={ column.options.length > 1 ? [] : [column.options[0].id] }
        treeCheckable
        multiple
        treeData={ nestedSet(column.options) }
        filterTreeNode={ (input: string, option: any) => {
          if (option) {
            const filteredInput = input.toLocaleLowerCase();
            const title = option.title && option.title.toLowerCase();

            if (title.includes(filteredInput)) {
              return true;
            }
          }

          return false;
        } }
        onChange={ (value: any) => {
          let newFilters = _.cloneDeep(filters);
          if (!value.length) {
            delete newFilters[column.key];
          } else {
            newFilters = Object.assign({}, filters, { [column.key]: value });
          }
          this.setState({
            filters: _.cloneDeep(newFilters),
          });
        } }
      />
    );
  };

  renderFilterDateRangePicker = (column: any, filters: any, tooltip: any) => {
    const changeDate = (value: moment.Moment | null, datePickerKey: 'startDate' | 'endDate') => {
      let newFilters = _.cloneDeep(filters);
      if (!value) {
        if (Object.keys(newFilters[column.key]).length > 1) {
          delete newFilters[column.key]?.[datePickerKey];
        } else {
          delete newFilters[column.key];
        }
      } else {
        newFilters = Object.assign({}, filters, { [column.key]: { ...filters[column.key], [datePickerKey]: value } });
      }
      this.setState({
        filters: _.cloneDeep(newFilters),
      });
    };

    return (
      <Tooltip
        key={ column.key }
        visible={ tooltip === column.key }
        title={ `Filter by ${column.title}` }
      >
        <DatePicker
          className={ 'm-5' }
          placeholder={ 'Select start date' }
          format={ getUserSetting('date_format') }
          value={ filters?.[column.key]?.startDate }
          disabledDate={ date => filters?.[column.key]?.endDate && date.isAfter(filters?.[column.key]?.endDate) }
          onChange={ (value) => changeDate(value, 'startDate') }
        />
        <DatePicker
          className={ 'm-5' }
          placeholder={ 'Select end date' }
          format={ getUserSetting('date_format') }
          value={ filters?.[column.key]?.endDate }
          disabledDate={ date => filters?.[column.key]?.startDate && date.isBefore(filters?.[column.key]?.startDate) }
          onChange={ (value) => changeDate(value, 'endDate') }
        />
      </Tooltip>
    );
  };

  render = () => {
    const { columns, items, dateRangeFilterFields } = this.props;
    const { filters, tooltip } = this.state;

    return (
      <div style={{ marginBottom: 10 }}>
        { columns
          .filter((column: any) => column.filterable)
          .map((column: any) => {
            if (!!dateRangeFilterFields && !_.isEmpty(dateRangeFilterFields) && dateRangeFilterFields.includes(column.key)) {
              return this.renderFilterDateRangePicker(column, filters, tooltip);
            } else if (column.type === 'nested') {
              return this.renderNestedSelect(column, items);
            } else {
              return this.renderFilterSelect(column, items);
            }
          })
        }
      </div>
    );
  };
};

export default Filters;
